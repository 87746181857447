import { axiosInstance } from "../../axios";
const GETSearchUserHistory = `/api/VisitHistory/SearchUserHistory`;
const POSTSearchUserHistoryByDate = `/api/VisitHistory/SearchHistoryByDate`;

const SearchUserHistory = async function() {
    try {
      const response = await axiosInstance.bhAPIInstance.get(
        GETSearchUserHistory
      );
      let data = parseList(response);
      return data;
    } catch (error) {
      return [];
    }
  };
  
const SearchUserHistoryByDate = async function(payload) {
  try {
    const response = await axiosInstance.bhAPIInstance.post(POSTSearchUserHistoryByDate, payload);
    return response.data;
  } catch (error) {
    return parseError(error);
  }
};


  const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
      list = [];
    }
    return list;
  };

  const parseError = error => {
    let stringMessage = error.response.data.Errors.join(" <br/> ");
    error.response.data.Message += "<br/>" + stringMessage;
    return error.response.data;
  };
  

  export const visitHistoryData = {
    SearchUserHistory,
    SearchUserHistoryByDate
  };
  